<script lang="tsx">
import { defineComponentBaseUiSkeleton } from '@core/app/components/base/ui/BaseUiSkeleton.vue'

type Colors = 'white' | 'gray'

export default defineComponentBaseUiSkeleton<Colors>({
    props: {
        color: {
            default: 'white',
        },
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiSkeleton" as *;

@include skeleton {
    border-radius: $sim-border-radius-sm;
}

@include skeleton--color('white') {
    @include set-skeleton-color($sim-c-white);
    @include set-skeleton-shine-color($sim-c-basic-100);
}

@include skeleton--color('gray') {
    @include set-skeleton-color($sim-c-basic-200);
    @include set-skeleton-shine-color($sim-c-basic-100);
}

</style>
